<template>
  <div class="game">
    <img
      ref="logo"
      class="game__logo"
      @load="logoLoaded"
      src="~@/assets/imgs/irish-aid-logo.png"
      alt="Irish Aid Our World Awards"
    />
    <img
      class="tree tree--left"
      ref="leftTree"
      @load="leftTreeIn"
      src="~@/assets/imgs/tree-with-monkey.png"
    />
    <img
      class="tree tree--right"
      ref="rightTree"
      @load="rightTreeIn"
      src="~@/assets/imgs/tree.png"
    />
    <img
      ref="giraffe"
      @load="giraffeIn"
      class="giraffe"
      src="~@/assets/imgs/giraffe.png"
    />
    <main class="game__content">
      <RouterView></RouterView>
    </main>
  </div>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'GameRoute',
  components: {},
  methods: {
    logoLoaded() {
      gsap.fromTo(
        this.$refs.logo,
        {
          y: '-10',
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          y: '0',
          duration: 0.5,
          delay: 1.5,
        }
      );
    },
    leftTreeIn() {
      gsap
        .fromTo(
          this.$refs.leftTree,
          {
            x: '-10',
            rotation: -90,
          },
          {
            autoAlpha: 1,
            x: '0',
            rotation: 0,
            duration: 2,
            transformOrigin: 'bottom right',
            ease: 'elastic.out(1, 1.25)',
          }
        )
        .delay(0.25);
    },
    rightTreeIn() {
      gsap
        .fromTo(
          this.$refs.rightTree,
          {
            x: '10',
            rotation: 90,
          },
          {
            autoAlpha: 1,
            x: '0',
            rotation: 0,
            duration: 2,
            transformOrigin: 'bottom left',
            ease: 'elastic.out(1, 1.25)',
          }
        )
        .delay(0.5);
    },
    giraffeIn() {
      gsap
        .fromTo(
          this.$refs.giraffe,
          {
            x: '225%',
          },
          {
            autoAlpha: 1,
            x: '0',
            duration: 2,
            ease: 'elastic.out(1, .9)',
          }
        )
        .delay(0.5);
    },
  },
};
</script>

<style scoped>
/* Game backgrouind */
.game {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.game__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: 5;
}

/* Logo */
.game__logo {
  position: absolute;
  opacity: 0;
  top: 30px;
  left: 25px;
  width: 130px;
  z-index: 20;
}

@media only screen and (min-width: 600px) {
  .game__logo {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) {
  .game__logo {
    top: 40px;
  }
}

/* Trees */
.tree {
  z-index: 1;
  position: absolute;
  visibility: hidden;
  bottom: -2.5%;
  max-height: 400px;
}

@media only screen and (min-width: 800px) {
  .tree {
    max-height: 60%;
  }
}

.tree--left {
  left: -60%;
  transform: rotate(0);
}

@media only screen and (min-width: 350px) {
  .tree--left {
    left: -35%;
  }
}

@media only screen and (min-width: 475px) {
  .tree--left {
    left: -30%;
  }
}

@media only screen and (min-width: 600px) {
  .tree--left {
    left: -20%;
  }
}

@media only screen and (min-width: 800px) {
  .tree--left {
    left: -10%;
  }
}

@media only screen and (min-width: 1400px) {
  .tree--left {
    left: -5%;
  }
}

.tree--right {
  display: none;
  right: -10%;
  transform: rotate(90deg);
}

@media only screen and (min-width: 800px) {
  .tree--right {
    display: block;
  }
}

@media only screen and (min-width: 1400px) {
  .tree--right {
    right: -5%;
  }
}

/* Giraffe */
.giraffe {
  display: none;
  position: absolute;
  bottom: -2%;
  right: 10%;
  max-height: 40%;
  z-index: 5;
}

@media only screen and (min-width: 800px) {
  .giraffe {
    display: block;
  }
}
</style>
