export function debounce(fnBefore, fnAfter, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    fnBefore.apply(that, args);
    timeoutID = setTimeout(function () {
      fnAfter.apply(that, args);
    }, delay);
  };
}
