<template>
  <transition appear :css="false">
    <div ref="score" class="score" :class="classNames">
      <span class="score__value">{{ score }}</span>
      <span class="score__label">Score</span>
    </div>
  </transition>
</template>

<script>
import { categories } from '@/game';

export default {
  name: 'Score',
  data() {
    return {};
  },
  computed: {
    score: () => {
      return String(categories.correct.length);
    },
    classNames() {
      return `${categories.scoreVisible ? 'score--is-visible' : ''}`;
    },
  },
};
</script>

<style scoped>
.score {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'StantonICG', Arial, Helvetica, sans-serif;
  font-size: 4rem;
  line-height: 1;
  color: white;
  text-align: center;
  background-color: #dc1768;
  box-shadow: 2px 2px 0px 2px #ffffff;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  transition: all 200ms linear;
  transform: translate3d(0, 180px, 0);
}

.score--is-visible {
  transform: translate3d(0, 0, 0);
}

.score__label {
  position: absolute;
  top: 15%;
  left: 50%;
  opacity: 0.5;
  transform: translate3d(-50%, 0, 0);
  font-size: 2rem;
  line-height: 1;
}

.score__value {
  margin-top: 20%;
}

@media only screen and (min-width: 600px) {
  .score {
    height: 85px;
    width: 85px;
    bottom: 30px;
    right: 30px;
    font-size: 6rem;
    box-shadow: 3px 3px 0px 3px #ffffff;
  }
}

@media only screen and (min-width: 900px) {
  .score {
    height: 100px;
    width: 100px;
    bottom: 30px;
    right: 30px;
    font-size: 8rem;
    box-shadow: 3px 3px 0px 3px #ffffff;
  }
}
</style>
