<template>
  <transition :css="false" appear @enter="enter" @leave="leave">
    <RouteWrapper>
      <SpeechBubble ref="bubble" :color="'#01699D'" :size="question.size">
        <template v-slot:header>
          <div class="question">
            <img
              class="question__icon"
              v-if="question.questionIcon"
              :src="require(`@/assets/imgs/${question.questionIcon}`)"
              alt="Icon"
            />
            <p class="question__text">
              <strong>{{ question.question }}</strong>
            </p>
          </div>
        </template>
        <template v-slot:content>
          <AnswersTextList
            v-if="question.options"
            :options="question.options"
            @answerClicked="answerClicked"
          ></AnswersTextList>
          <AnswersImageList
            v-if="question.images"
            :options="question.images"
            @answerClicked="answerClicked"
          ></AnswersImageList>
        </template>
      </SpeechBubble>
    </RouteWrapper>
  </transition>
</template>

<script>
import SpeechBubble from '@/components/SpeechBubble';
import AnswersImageList from '@/components/AnswersImageList';
import AnswersTextList from '@/components/AnswersTextList';
import RouteWrapper from '@/components/RouteWrapper';
import { gsap } from 'gsap';
import { categories } from '@/game';
import { timer } from '@/timer';

export default {
  name: 'QuestionDisplayRoute',
  components: {
    SpeechBubble,
    AnswersTextList,
    AnswersImageList,
    RouteWrapper,
  },
  props: ['category', 'question'],
  created() {
    if (categories.completed.includes(this.category)) {
      this.$router.replace({ name: 'question-already-answered' });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (timer.isEnabled) {
      timer.contract();
      timer.reset();
    }
    next();
  },
  methods: {
    answerClicked(i) {
      // Add this question to "completed" list
      categories.completed.push(this.category);

      // Go to correct/incorrect route
      if (this.question.answers.includes(i + 1)) {
        categories.correct.push(this.category);
        this.$router.replace({ name: 'question-correct' });
      } else {
        this.$router.replace({ name: 'question-incorrect' });
      }
    },
    enter(el, done) {
      if (timer.isEnabled) {
        timer.expand();
        timer.reset();
        timer.start().then(() => {
          // Timer has run out
          categories.completed.push(this.category);
          this.$router.replace({ name: 'question-incorrect' });
        });
      }

      gsap
        .timeline({
          onComplete: done,
          delay: 0.75,
        })
        .fromTo(
          this.$refs.bubble.$el,
          {
            y: '-40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power3.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.bubble.$el,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '40px',
            autoAlpha: 0,
            duration: 0.5,
            ease: 'power3.out',
          }
        );
    },
  },
};
</script>

<style scoped>
.question {
  display: flex;
  flex-direction: column;
}

.question__icon {
  width: 60px;
  align-self: center;
  margin-right: 15px;
  border-radius: 6px;
  border: 5px solid white;
  transform: rotate(-10deg);
}

@media only screen and (min-width: 374px) {
  .question__icon {
    width: 80px;
  }
}

@media only screen and (min-width: 600px) {
  .question {
    flex-direction: row;
  }
  .question__icon {
    width: 80px;
  }
}
</style>
