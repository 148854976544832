<template>
  <transition appear :css="false" @enter="enter" @leave="leave" mode="in-out">
    <div class="overlay">
      <div class="overlay__content">
        <RouterView :category="category"></RouterView>
      </div>
      <div ref="background" class="overlay__background">&nbsp;</div>
      <img
        ref="character"
        class="character"
        src="~@/assets/imgs/character-one.png"
      />
    </div>
  </transition>
</template>

<script>
import { categories } from '@/game';
import { timer } from '@/timer';
import { gsap } from 'gsap';

export default {
  name: 'CategoryRoute',
  data() {
    return {
      category: undefined,
    };
  },
  props: ['categoryId'],
  created() {
    // Todo: Get category from remaining questions or 404
    this.category = categories.all[this.categoryId];
  },
  methods: {
    enter(el, done) {
      categories.scoreVisible = false;
      if (timer.isEnabled) {
        timer.show();
      }
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.background,
          {
            left: '100%',
            autoAlpha: 0,
          },
          {
            autoAlpha: 0.75,
            left: 0,
            duration: 0.5,
            ease: 'power4.out',
          }
        )
        .fromTo(
          this.$refs.character,
          {
            y: '100%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'expo',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.character,
          {
            y: 0,
          },
          {
            y: '100%',
            duration: 0.5,
            ease: 'back.in',
          },
          0
        )
        .fromTo(
          this.$refs.background,
          {
            left: 0,
            autoAlpha: 0.75,
          },
          {
            autoAlpha: 0,
            left: '100%',
            duration: 0.5,
            ease: 'power1.out',
          }
        );
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.overlay__content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.overlay__background {
  background-color: white;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

/**
 * Character
 */
.character {
  pointer-events: none;
  display: none;
  position: absolute;
  height: 400px;
  bottom: -75px;
  z-index: 10;
  left: 0;
  transform: rotate(5deg);
}

@media only screen and (min-width: 600px) {
  .character {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .character {
    height: 500px;
    bottom: -75px;
  }
}

@media only screen and (min-width: 1800px) {
  .character {
    height: 600px;
    bottom: -85px;
    left: 5%;
  }
}

@media only screen and (min-width: 2200px) {
  .character {
    height: 700px;
    bottom: -85px;
    left: 10%;
  }
}
</style>
