<template>
  <div
    :class="'speech-bubble speech-bubble--' + size"
    :style="'background-color:' + color"
  >
    <div ref="speechBubbleInner" class="speech-bubble__inner">
      <header class="speech-bubble__header">
        <slot name="header"></slot>
      </header>
      <main class="speech-bubble__content">
        <slot name="content"></slot>
      </main>
    </div>
    <svg
      class="speech-bubble__background speech-bubble__background--desktop"
      clip-rule="evenodd"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      viewBox="0 0 4564 3490"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4399.75 1308.84c-331.112-923.594-1205.25-1323.37-2022.44-1308.44-418.338 7.631-864.7 96.275-1224.99 366.206-262.206 196.444-280.3 254.069-612.763 813.006-94.975 159.656-545.137 527.138-539.5 723.331 4.788 167.707 453.7-250.631 577.238 284.15 394.794 1248.41 1660 1256.11 1664.04 1241.64 533.437 3.556 857.587-70.156 1360.33-294.575 294.843-131.681 602.562-332.437 769.356-660.069 183.544-360.462 162.406-792.475 28.737-1165.26"
        fill="#fff"
      />
      <path
        d="m4458.98 1366.69c-331.112-923.593-1205.25-1323.37-2022.45-1308.44-418.337 7.625-864.7 96.275-1224.99 366.206-262.206 196.438-280.3 254.069-612.762 813.006-94.975 159.657-545.131 527.138-539.494 723.332 4.781 167.7 453.694-250.638 577.231 284.15 394.794 1248.41 1660 1256.11 1664.04 1241.64 533.438 3.557 857.588-70.156 1360.33-294.575 294.844-131.681 602.563-332.437 769.356-660.068 183.55-360.463 162.413-792.482 28.744-1165.26"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SpeechBubble',
  props: {
    color: {
      type: String,
      default: '#BA56A0',
    },
    size: {
      type: String,
      default: 'small', // large | small
    },
  },
};
</script>

<style scoped>
.speech-bubble {
  position: relative;
  width: calc(100% - 40px);
  max-height: 70%;
  margin: 75px 0 20px 0;
  overflow-y: auto;

  background-color: #ba56a0;
  box-shadow: -6px -5px 0px 0px #ffffff;
  border-radius: 20px;
}

@media only screen and (min-width: 425px) {
  .speech-bubble {
    width: 70%;
  }
}

@media only screen and (min-width: 600px) {
  .speech-bubble {
    margin: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .speech-bubble {
    width: auto;
    background: none !important;
    box-shadow: none;
    border-radius: 0;
    overflow: inherit;
  }
}

/* Inner */
.speech-bubble__inner {
  position: relative;
  padding: 20px;
  z-index: 15;
  height: 100%;

  color: white;
  font-family: 'DIN Next LT Pro', 'Helvetica', 'sans-serif';
}

@media only screen and (min-width: 1000px) {
  .speech-bubble__inner {
    min-width: 425px;
    max-width: 550px;
  }
}

.speech-bubble--large .speech-bubble__inner {
  max-width: 600px;
}

/* Background */
.speech-bubble__background {
  display: none;
  position: absolute;
  height: 175%;
  width: 175%;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate3d(-55%, -50%, 0) rotate(-10deg);
}

@media only screen and (min-width: 1000px) {
  .speech-bubble__background {
    display: block;
  }
}

/* Header */
.speech-bubble__header {
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header {
    margin-bottom: 25px;
  }
}

.speech-bubble__header h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble__header h2 {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header h2 {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 1000px) {
  .speech-bubble__header h2 {
    font-size: 5rem;
  }
}

.speech-bubble__header p {
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble__header p {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header p {
    font-size: 2.6rem;
  }
}

.speech-bubble--large .speech-bubble__header p {
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble--large .speech-bubble__header p {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble--large .speech-bubble__header p {
    font-size: 2.4rem;
  }
}

/* Content */
.speech-bubble__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
