<template>
  <div class="wheel" ref="container">
    <svg class="wheel__svg" ref="svg" viewBox="-1 -1 2 2">
      <circle cx="0" cy="0" r="1" fill="white"></circle>
      <g v-for="sector in sectorsData" :key="sector.i">
        <path
          :d="sector.path.d"
          :fill="sector.enabled ? sector.path.fill : '#ccc'"
        ></path>
        <text
          fill="white"
          :x="sector.text.x"
          :y="sector.text.y"
          :dy="sector.text.dy"
          :font-size="sector.text.fontSize"
          :transform="sector.text.transform"
        >
          {{ sector.text.content }}
        </text>
        <!-- <circle :cx="sector.text.x" :cy="sector.text.y" r=".01" fill="red" /> -->
      </g>
    </svg>
    <img class="wheel-center" src="~@/assets/imgs/wheel-center.svg" />
  </div>
</template>

<script>
import { TimelineMax, TweenMax, Power4, Back } from 'gsap';

export default {
  name: 'Wheel',
  data() {
    this.sectorAngle = 360 / this.sectors.length;
    this.sectorCount = this.sectors.length;
    let fontSize = 0.05;
    let outerRadius = 0.93;
    let innerRadius = 0.25;
    let outerOffset = 1.75;
    let innerOffset = 4;
    let sectorAngleA = 180 - this.sectorAngle / 2; // start at 9 o'clock

    let sectorsData = this.sectors.map((sector, i) => {
      const largeArcFlag = this.sectorAngle > 180 ? 1 : 0;
      const sectorAngleB = sectorAngleA + this.sectorAngle / 2;
      const sectorAngleC = sectorAngleA + this.sectorAngle;

      const [x1, y1] = this.$_getCoordsOnCircle(
        sectorAngleA,
        outerRadius,
        outerOffset
      );
      const [x2, y2] = this.$_getCoordsOnCircle(
        sectorAngleA,
        innerRadius,
        innerOffset
      );
      const [x3, y3] = this.$_getCoordsOnCircle(
        sectorAngleC,
        outerRadius,
        -outerOffset
      );
      const [x4, y4] = this.$_getCoordsOnCircle(
        sectorAngleC,
        innerRadius,
        -innerOffset
      );
      const [x5, y5] = this.$_getCoordsOnCircle(
        sectorAngleB,
        outerRadius * 0.975
      );

      let data = {
        i,
        enabled: sector.enabled, //
        angleA: sectorAngleA,
        angleB: sectorAngleB,
        angleC: sectorAngleC,
        color: sector.color,
        text: {
          x: x5,
          y: y5,
          dy: `${fontSize * 0.33}`,
          // transformOrigin: `${x5} ${y5}`,
          fontSize: fontSize,
          content: `${i + 1}. ${sector.text}`,
          transform: `rotate(${sectorAngleB + 180}, ${x5}, ${y5})`,
        },
        path: {
          fill: sector.color,
          d: [
            `M ${x2} ${y2}`,
            `L ${x1} ${y1}`,
            `A 1 1 0 ${largeArcFlag} 1 ${x3} ${y3}`,
            `L ${x4} ${y4}`,
            `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x2} ${y2}`,
          ].join(' '),
        },
      };

      sectorAngleA = sectorAngleC;
      if (sectorAngleA > 360) {
        sectorAngleA = sectorAngleA - 360;
      }

      return data;
    });

    return {
      sectorsData,
      spinDuration: 6,
      isSpinning: false,
      minSpins: 3,
      maxSpins: 4,
      rotation: 0,
    };
  },
  props: {
    sectors: Array,
  },
  methods: {
    /**
     * The internal sector are rotated internally with the circle
     * so that the "1st" appears readable on the left of the circle.
     * This offset needs to be accounted for when spinning the wheel
     * so that we know where to land
     */
    $_getCoordsOnCircle(degrees, radius = 1, offset = 0) {
      const radians = (degrees + offset) * (Math.PI / 180.0);
      const x = radius * Math.cos(radians);
      const y = radius * Math.sin(radians);
      return [x, y];
    },
    focus(i) {
      if (i >= 0 && i < this.sectorsData.length) {
        this.sectorsData.forEach((sector, j) => {
          if (sector.enabled && i == j) {
            sector.path.fill = sector.color;
          } else {
            sector.path.fill = '#ccc';
          }
        });
      }
    },
    unfocus() {
      this.sectorsData.forEach((sector, i) => {
        if (sector.enabled) {
          sector.path.fill = sector.color;
        }
      });
    },
    disable(i) {
      this.sectorsData[i].enabled = false;
    },
    reset() {
      this.sectorsData.forEach((segment) => (segment.enabled = true));
    },
    /**
     * Spin the wheel between `this.minSpins` and `this.maxSpins` times
     */
    spin() {
      if (this.isSpinning) {
      } else {
        this.isSpinning = true;

        let sector = false;

        while (!sector) {
          const minSpins = 4;
          const maxSpins = 8;
          const durationPerSpin = 2;
          const grabAnimationTolerance = 20; // The degrees to animate the "grab" at the start of the animation
          const backAnimationTolerance = 0.35; // How much to overshoot at end of animation
          const nearlyCompleteTolerance = 0.6;

          const newRotation =
            Math.floor(Math.random() * (maxSpins * 360 - minSpins * 360 + 1)) +
            minSpins * 360;
          const normalisedRotation = newRotation % 360;

          const spinCount = Math.floor(newRotation / 360);
          const duration = spinCount * durationPerSpin;

          // Translate the new circle rotation to find which sector it's falling within
          let searchDegree =
            normalisedRotation <= 180
              ? 180 - normalisedRotation
              : 540 - normalisedRotation;
          sector = this.sectorsData
            .filter((sector) => sector.enabled)
            .find((sector) => {
              // The +/- values are to help try to avoid the spinner landing
              // in the white zones between the segments.
              return (
                searchDegree > sector.angleA + 5 &&
                searchDegree < sector.angleC - 5
              );
            });

          console.debug('Current Rotation: ', this.rotation);
          console.debug('New Rotation:', newRotation);
          console.debug('Normalised Rotation:', normalisedRotation);
          console.debug('Spin Count:', spinCount);
          console.debug('Spin Duration:', duration);
          console.debug('Winning sector', sector);

          if (sector) {
            let nearlyComplete = false;
            let lastClack = this.rotation;
            let lastSpin = this.rotation;
            const container = this.$refs['container'];
            this.spinner = new TimelineMax();
            this.spinner
              // The initial "grab" of the wheel
              .to(container, 1, {
                rotation: this.rotation - grabAnimationTolerance,
                transformOrigin: '50% 50%',
                ease: Power4.easeOut,
              })
              // The actual spin of the wheel
              .to(container, duration, {
                rotation: newRotation,
                transformOrigin: '50% 50%',
                ease: Back.easeOut.config(backAnimationTolerance),
                // Every loop of the animation
                onUpdate: () => {
                  // Play sound effect
                  // We need to play a "click" sound every time the wheel
                  // passes the edge of a sector/category. To do so we need
                  // to get the underlying current rotation and see if it's
                  // passed the edge of a sector
                  const currentDegreeStr = container._gsap.rotation;
                  const currentDegree = parseFloat(
                    currentDegreeStr.slice(0, currentDegreeStr.length - 3)
                  );
                  if (currentDegree > lastClack + this.sectorAngle * 1.5) {
                    const click = new Audio('/sounds/clack-1.mp3');
                    const clack = new Audio('/sounds/clack.mp3');
                    clack.delay = 0.5;
                    click.play();
                    clack.play();
                    this.$emit('clack');
                    lastClack = currentDegree;
                  }
                  if (currentDegree > lastSpin + 270) {
                    let squeak = new Audio('/sounds/squeak.mp3');
                    squeak.volume = 0.1;
                    squeak.play();
                    lastSpin = currentDegree;
                  }

                  // If nearly complete, zoom in
                  if (!nearlyComplete) {
                    if (this.spinner.progress() > nearlyCompleteTolerance) {
                      nearlyComplete = true;
                      this.$emit('nearlyFinished', sector.i);
                    }
                  }
                },
                // Update everything to the new position
                onComplete: () => {
                  this.isSpinning = false;
                  this.rotation = normalisedRotation;
                  TweenMax.set(container, { rotate: normalisedRotation });
                  new Audio('/sounds/happy-bell-alert.mp3').play();
                  this.$emit('finished', sector.i);
                },
              });
          }
        }
      }
    },
  },
};
</script>

<style>
.wheel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.wheel__svg {
  height: 100%;
  width: 100%;
  font-family: 'DIN Condensed', 'Helvetica', 'arial', 'sans-serif';
}

.wheel__svg path {
  transition: fill linear 400ms;
}

.wheel-center {
  position: absolute;
  z-index: 1;
  height: 20%;
  width: 20%;
  opacity: 0.1;
}
</style>
