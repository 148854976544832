<template>
  <button
    :class="'button button--' + size"
    @click="$emit('click', $event)"
    @mousedown="buttonDown()"
  >
    <span class="button__content"><slot></slot></span>
    <img
      class="button__background"
      :src="require(`@/assets/imgs/${this.color}-button-background.svg`)"
      alt=""
    />
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    color: {
      type: String,
      default: 'pink',
    },
  },
  data() {
    const url = require(`@/assets/imgs/${this.color}-button-background.svg`);
    return {
      style: `background-image: url("${url}")`,
      pressed: undefined,
      depressed: undefined,
    };
  },
  methods: {
    buttonDown() {
      this.pressed = Date.now();
      new Audio('/sounds/button.mp3').play();
    },
  },
};
</script>

<style scoped>
.button {
  position: relative;
  display: block;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 20px;
  color: white;
  font-size: 3rem;
  line-height: 1;
  text-decoration: none;
  font-family: 'StantonICG', 'Helvetica', 'sans-serif';
  transition: all ease-out 100ms;
  outline: none;
  min-width: 125px;
}

@media only screen and (min-width: 400px) {
  .button {
    font-size: 5rem;
    min-width: 160px;
  }
}

@media only screen and (min-width: 800px) {
  .button {
    font-size: 6rem;
    min-width: 200px;
    padding: 20px 40px;
  }
}

.button:hover {
  transform: scale(1.05) rotate(-3deg) translate3d(0, -2px, 0);
}

.button__content {
  display: block;
  position: relative;
  transition: transform ease-out 150ms;
  z-index: 5;
}

.button:hover .button__content {
  transform: scale(1.05);
}

.button:active {
  top: -3px;
  left: -3px;
}

.button--small {
  font-size: 3rem;
}

@media only screen and (min-width: 400px) {
  .button--small {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1000px) {
  .button--small {
    font-size: 4rem;
  }
}

.button__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 0;
}
</style>
