import Vue from 'vue';
import data from './data.js';

const categories = Vue.observable({
  isLoaded: false,
  all: data,
  completed: [],
  correct: [],
  scoreVisible: false,
  remaining() {
    const indexes = this.completed.map((category) => category.id);
    return this.all.filter((category) => !indexes.includes(category.id));
  },
});

const resetGame = () => {
  categories.completed = [];
  categories.correct = [];
  categories.scoreVisible = false;
};

const isOver = () => {
  return categories.completed.length === categories.all.length;
};

export { categories, resetGame, isOver };
