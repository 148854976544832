export default [
  {
    id: 0,
    title: 'No Poverty',
    color: '#E4263C',
    questions: [
      {
        question:
          'The Global goals set by the United Nation want to end poverty by which year?',
        options: ['2040', '2030', '2050'],
        answers: [2],
      },
      {
        question: 'The Irish for No Poverty is',
        options: [
          'Deireadh le bochtaineacht',
          'Deireadh le hocras',
          'Dea-shláinte',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 1,
    title: 'Zero Hunger',
    color: '#DEA739',
    questions: [
      {
        question: 'How many people in the world suffer from hunger?',
        options: ['1 in 10', '1 in 15', '1 in 20'],
        answers: [1],
      },
      {
        size: 'large',
        question:
          'Petelina is sharing her knowledge of wild and traditional foods with others and teaching them about their nutritional value. This is important for Global Goal 2 and what other Global Goal (Hint : Pages 6 and 7 of pupils` magazine)?',
        options: [
          'Life below water',
          'Affordable and clean energy',
          'Life on land ',
        ],
        answers: [3],
      },
    ],
  },
  {
    id: 2,
    title: 'Good health and well being',
    color: '#4B9E46',
    questions: [
      {
        question:
          'What is the main cause of death in children under 5 years in the developing world?',
        options: [
          'Malnutrition',
          'Ware',
          'Communicable diseases e.g cholera, COVID-19',
        ],
        answers: [1],
      },
      {
        question:
          'Irish Aid has supported the building of health centres in this country (Hint: Page 5 of pupils` magazine)',
        options: ['Madagascar', 'Mozambique', 'Malawi'],
        answers: [2],
      },
    ],
  },
  {
    id: 3,
    title: 'Quality Education',
    color: '#C5212F',
    questions: [
      {
        question:
          'How many children around the world do not go to school because they are forced to work?',
        options: ['One in 15', 'One in 7', 'One in 10'],
        answers: [2],
      },
      {
        question:
          'Who said, "One child, one teacher, one book and one pen can change the world"?',
        options: ['Malala Yousafzai', 'Greta Thunberg', 'Vanessa Nakate'],
        answers: [1],
      },
    ],
  },
  {
    id: 4,
    title: 'Gender Equality',
    color: '#EE402B',
    questions: [
      {
        question: 'Which of the following are important in gender equality?',
        options: [
          'Equal pay for men and women',
          'Girls receiving the same level of education as boys',
          'Women being able to receive loans to start a business',
          'All of these',
        ],
        answers: [4],
      },
      {
        size: 'large',
        question:
          'Trocaire and Irish Aid have piloted GALS to support women to come up with their own solutions to problems. What does GALS stand for? (Hint: Page pupils` magazine)',
        options: [
          'Girls Achieve Learning Systems',
          'Gender Action Learning Systems ',
          'Girls Always Learn at School',
        ],
        answers: [2],
      },
    ],
  },
  {
    id: 5,
    title: 'Clean water and sanitation',
    color: '#27BCE1',
    questions: [
      {
        question:
          "Lack of clean water affects what percentage of the world's population?",
        options: ['50%', '60%', '40%'],
        answers: [3],
      },
      {
        size: 'large',
        question:
          "This country which is one of Irish Aid's partner countries is home to the largest waterfall in the world, Victoria Falls. What country is it? (Hint: page 4, pupils' magazine)",
        options: ['Zimbabwe', 'Vietnam', 'Zambia '],
        answers: [3],
      },
    ],
  },
  {
    id: 6,
    title: 'Affordable and clean energy',
    color: '#F8C315',
    questions: [
      {
        question: 'What is the most used renewable energy in the world?',
        options: ['Solar', 'Wind', 'Hydro'],
        answers: [3],
      },
      {
        question:
          'In Inhambane, Irish Aid is supporting solar-powered water systems. Where is Inhambane? (Hint: Page 5 Pupils` magazine)',
        options: ['Mozambique', 'Tanzania', 'Ethiopia'],
        answers: [1],
      },
    ],
  },
  {
    id: 7,
    title: 'Decent work and economic growth',
    color: '#A21C44',
    questions: [
      {
        question: 'Which of the following equal decent work?',
        options: [
          'A safe place to work',
          'Social protection for families',
          'A lunch hour',
          'Appropriate wages',
        ],
        answers: [1, 2, 4],
      },
      {
        size: 'large',
        question:
          'Irish Aid has assisted farmers to grow more nutritious food for themselves and for their families in Ethiopia. Which of the following goals does this help to achieve?',
        options: ['Gender equality', 'Quality education', 'Zero hunger'],
        answers: [3],
      },
    ],
  },
  {
    id: 8,
    title: 'Industry, innovation and infrastructure',
    color: '#F16A2C',
    questions: [
      {
        question:
          'In Malawi, Irish Aid has helped to re-establish this industry. (Hint: Page 5 pupils` magazine)',
        options: ['Coffee', 'Peanuts', 'Chocolate'],
        answers: [2],
      },
      {
        question:
          'Which of these industries causes the least environmental damage?',
        options: [
          'The fashion industry',
          'The construction industry',
          'The peanut industry',
        ],
        answers: [3],
      },
    ],
  },
  {
    id: 9,
    title: 'Reduced inequalities',
    color: '#DC1768',
    questions: [
      {
        question:
          'How much of the world`s wealth does the richest 1% of the population have?',
        options: ['15%', '30%', '50%'],
        answers: [3],
      },
      {
        question:
          'Irish Aid works with some of the poorest countries in the world. Which is not considered a poor country?',
        options: ['Japan', 'Vietnam', 'Sierra Leone'],
        answers: [1],
      },
    ],
  },
  {
    id: 10,
    title: 'Sustainable cities and communities',
    color: '#F89C27',
    questions: [
      {
        question:
          'Which of these cities are often included in the top 10 greenest cities in the world?',
        options: [
          'Lisbon, Portugal',
          'Miami, U.S.',
          'Johannesburg, R.S.A',
          'Sao Paulo, Brazil',
        ],
        answers: [1, 4],
      },
      {
        question:
          'Which type of transport is the most polluting on a 10km journey in a city?',
        options: ['Bus', 'Bicycle', 'Car'],
        answers: [3],
      },
    ],
  },
  {
    id: 11,
    title: 'Reduced consumption and production',
    color: '#BE8A2C',
    questions: [
      {
        question:
          'If we continue to use as much as we are doing now, by 2050 how many planets will we need?',
        options: ['2', '4', '3'],
        answers: [3],
      },
      {
        question: 'How much food is wasted every day across the world?',
        options: ['25%', '33%', '28%'],
        answers: [2],
      },
    ],
  },
  {
    id: 12,
    title: 'Climate action',
    color: '#417F44',
    questions: [
      {
        question: 'The Paris Agreement of 2015 agreed to?',
        options: [
          'Protect biodiversity and the deforestation of the world`s rainforests',
          'To keep global temperatures well below 2 degrees C preindustrial levels and to pursue a path to limit warning to 1.5 degrees C',
          'To limit seal level rise to 1 metre above current levels',
          'To pursue a goal of 100% clean renewable energy',
        ],
        answers: [2],
      },
      {
        question:
          'Nkusi Gerald is a youth ambassador for climate action . His dream is to plant trees in Uganda. How many does he want to grow by 2030? (Hint: Page 3 pupils` magazine)',
        options: ['10 million', '5 million', '15 million'],
        answers: [2],
      },
    ],
  },
  {
    id: 13,
    title: 'Life below water',
    color: '#1995D3',
    questions: [
      {
        question:
          'At the current rate, there will be more plastic in the ocean than fish by 2050. Is this:',
        options: ['True', 'False'],
        answers: [1],
      },
      {
        question:
          'Lake Malawi is the fifth largest freshwater lake in the world. Which large fish can be found here? (Hint: page 5 pupils` magazine)',
        options: ['African catfish', 'Tilapia', 'Kampango'],
        answers: [3],
      },
    ],
  },
  {
    id: 14,
    title: 'Life on land',
    color: '#5BB947',
    questions: [
      {
        question:
          'Stopping forests disappearing is really important in combating climate change because:',
        options: [
          'They are green spaces to walk in',
          'Trees provide oxygen',
          'We need wood to build houses and make other products',
        ],
        answers: [2],
      },
      {
        question:
          'Which bird is represented on the flag of Uganda? (Hint: Page 5 pupils` magazine)',
        options: [
          'African fish eagle',
          'Lilac breasted roller',
          'Grey crowned crane',
        ],
        answers: [3],
      },
    ],
  },
  {
    id: 15,
    title: 'Peace, Justice and Strong Institutions',
    color: '#00699D',
    questions: [
      {
        question: 'What group of people have the name "Blue Helmets"?',
        options: [
          'Cyclists',
          'Police officers',
          'U.N peace keeping soldiers',
          'Volunteers in the navy',
        ],
        answers: [3],
      },
      {
        questionIcon: 'answer-4-1.png',
        question: 'Which bird is represented here in Global Goal 16',
        options: ['Dove', 'Pigeon', 'Seagull'],
        answers: [1],
      },
    ],
  },
  {
    id: 16,
    title: 'Partnership for the goals',
    color: '#18496B',
    questions: [
      {
        question: 'What does the U.N stand for?',
        options: ['Urban Network', 'Undeveloped Nations', 'United Nations'],
        answers: [3],
      },
      {
        size: 'large',
        question:
          'Irish Aid partnered with Trocaire in which country to support them in agroecology. (Hint: Pages 6 and 7 pupils` magazine)',
        options: ['Uganda', 'Malawi', 'Tanzania'],
        answers: [2],
      },
    ],
  },
];
