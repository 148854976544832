<template>
  <transition appear :css="false">
    <div ref="stopwatch" class="stopwatch" :class="classNames">
      <img class="stopwatch__image" src="~@/assets/imgs/stopwatch.png" />
      <div class="stopwatch__time">:{{ time }}</div>
    </div>
  </transition>
</template>

<script>
import { timer } from '@/timer';

export default {
  name: 'Stopwatch',
  data() {
    return {
      timer,
    };
  },
  computed: {
    time: () => {
      return String(timer.time).padStart(2, '0');
    },
    classNames() {
      return `${timer.isVisible ? 'stopwatch--is-visible' : ''} ${
        timer.isExpanded ? 'stopwatch--is-expanded' : ''
      }`;
    },
  },
};
</script>

<style scoped>
.stopwatch {
  position: fixed;
  top: 30px;
  right: 0;
  z-index: 100;
  display: flex;
  transition: transform 200ms linear;
  transform: translate3d(100%, 0, 0);
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-left: 5px solid #f8c315;
  border-top: 5px solid #f8c315;
  border-bottom: 5px solid #f8c315;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}

.stopwatch--is-visible.stopwatch--is-expanded {
  transform: translate3d(0, 0, 0);
}

.stopwatch--is-visible:not(.stopwatch--is-expanded) {
  transform: translate3d(60px, 0, 0);
}

@media only screen and (min-width: 600px) {
  .stopwatch {
    padding: 15px 30px;
    border-top-left-radius: 90px;
    border-bottom-left-radius: 90px;
  }

  .stopwatch--is-visible:not(.stopwatch--is-expanded) {
    transform: translate3d(100px, 0, 0);
  }
}

.stopwatch__image {
  height: 30px;
}

@media only screen and (min-width: 600px) {
  .stopwatch__image {
    height: 70px;
  }
}

.stopwatch__time {
  font-family: 'StantonICG', Arial, Helvetica, sans-serif;
  font-size: 3rem;
  color: #dc1768;
  margin-left: 15px;
  text-align: center;
  width: 40px;
}

@media only screen and (min-width: 600px) {
  .stopwatch__time {
    font-size: 6rem;
    width: 70px;
  }
}
</style>
