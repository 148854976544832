<template>
  <RouterView :question="question" :category="category"></RouterView>
</template>

<script>
export default {
  name: 'QuestionRoute',
  data() {
    return {
      question: undefined,
    };
  },
  props: ['category', 'questionId'],
  created() {
    // Todo: Get category from remaining questions or 404
    this.question = this.category.questions[this.questionId];
  },
};
</script>

<style scoped></style>
