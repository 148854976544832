<template>
  <transition :css="false" appear @enter="enter">
    <RouteWrapper>
      <div class="question-result">
        <SpeechBubble ref="bubble" :color="'#01699D'">
          <template v-slot:header>
            <h2>Incorrect</h2>
            <p v-if="question.extraText">{{ question.extraText }}</p>
            <p v-else>Better luck next time</p>
          </template>
          <template v-slot:content>
            <AnswersTextResultList
              v-if="question.options"
              :options="question.options"
              :answers="question.answers"
            ></AnswersTextResultList>
            <AnswersImageResultList
              v-if="question.images"
              :options="question.images"
              :answers="question.answers"
            ></AnswersImageResultList>
          </template>
        </SpeechBubble>
        <footer class="question-result__footer">
          <RouterLink :to="{ name: 'play' }" replace>
            <Button ref="spinAgainButton" :size="'small'">Spin Again</Button>
          </RouterLink>
        </footer>
      </div>
    </RouteWrapper>
  </transition>
</template>

<script>
import SpeechBubble from '@/components/SpeechBubble';
import Button from '@/components/Button';
import AnswersTextResultList from '@/components/AnswersTextResultList';
import AnswersImageResultList from '@/components/AnswersImageResultList';
import RouteWrapper from '@/components/RouteWrapper';
import { gsap } from 'gsap';

export default {
  name: 'QuestionResult',
  components: {
    SpeechBubble,
    AnswersTextResultList,
    AnswersImageResultList,
    Button,
    RouteWrapper,
  },
  props: ['category', 'question', 'correct'],
  beforeRouteLeave(to, from, next) {
    /**
     * FIXME: Mega-hack. For whatever reason, the "leave()" transition hook
     * isn't firing when leaving this nested route to go back to the
     * game page. This is the only way I can get it to work
     */
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.bubble.$el,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y: '40px',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power3.out',
        }
      )
      .fromTo(
        this.$refs.spinAgainButton.$el,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y: '50px',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
        },
        0
      );
  },
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 0.75,
          onComplete: () => {
            new Audio('/sounds/incorrect.mp3').play();
          },
        })
        .fromTo(
          this.$refs.bubble.$el,
          {
            y: '-40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power3.out',
          }
        )
        .fromTo(
          this.$refs.spinAgainButton.$el,
          {
            y: '50px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.question-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.question-result__footer {
  margin-top: 20px;
}

@media only screen and (min-width: 600px) {
  .question-result__footer {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1000px) {
  .question-result__footer {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    margin-top: 0;
  }
}
</style>
