<template>
  <transition appear :css="false" @enter="enter">
    <RouteWrapper>
      <SpeechBubble ref="bubble" :color="'#BA56A0'">
        <template v-slot:header>
          <h1 class="question-selection__title">{{ category.title }}</h1>
          <p ref="header">Which question would you like to answer?</p>
        </template>
        <template v-slot:content>
          <ul ref="text" class="question-selection__list">
            <li
              class="question-selection__list__item"
              v-for="(question, i) in questions"
              :key="question"
            >
              <RouterLink
                replace
                :to="{
                  name: 'question-display',
                  params: { categoryId, questionId: i },
                }"
              >
                <Button
                  :size="'small'"
                  :color="i % 2 === 0 ? 'blue' : 'orange'"
                  >{{ question }}</Button
                >
              </RouterLink>
            </li>
          </ul>
        </template>
      </SpeechBubble>
    </RouteWrapper>
  </transition>
</template>

<script>
import SpeechBubble from '@/components/SpeechBubble';
import Button from '@/components/Button';
import { gsap } from 'gsap';
import RouteWrapper from '@/components/RouteWrapper';

export default {
  name: 'QuestionSelectionRoute',
  components: {
    SpeechBubble,
    Button,
    RouteWrapper,
  },
  props: ['category', 'categoryId', 'questionId'],
  computed: {
    questions() {
      return this.category.questions.map((question, i) => {
        return `Question ${i + 1}`;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    /**
     * FIXME: Mega-hack. For whatever reason, the "leave()" transition hook
     * isn't firing when leaving this nested route to go back to the
     * game page. This is the only way I can get it to work
     */
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.bubble.$el,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y: '40px',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power3.out',
        }
      );
  },
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 1,
        })
        .fromTo(
          this.$refs.bubble.$el,
          {
            y: '-40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power3.out',
          }
        );
    },
  },
};
</script>

<style scoped>
.question-selection__title {
  font-size: 20px;
  margin: 0;
  font-family: 'StantonICG', 'Helvetica', 'sans-serif';
}

@media only screen and (min-width: 800px) {
  .question-selection__title {
    font-size: 50px;
    margin: 0 0 15px 0;
  }
}

.question-selection__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 0 0;
  padding: 0;
}

@media only screen and (min-width: 800px) {
  .question-selection__list {
    margin: 20px 0 0 0;
  }
}

.question-selection__list__item {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
}

@media only screen and (min-width: 800px) {
  .question-selection__list__item {
    margin: 0 0 40px 0;
  }
}

.question-selection__list__item:last-child {
  margin-bottom: 0;
}

.question-selection__list__item a {
  text-decoration: none;
}
</style>
