<template>
  <ul class="answers-text-list" v-if="options.length > 0">
    <li
      :class="
        'answers-text-list__item answers-text-list__item--' +
        (option.correct ? 'correct' : 'incorrect')
      "
      v-for="(option, i) in localOptions"
      :key="i"
    >
      <span class="answers-text-list__item__checkbox">
        <TickBox :correct="option.correct"></TickBox>
      </span>
      <span class="answers-text-list__item__text">{{ option.value }}</span>
    </li>
  </ul>
</template>

<script>
import TickBox from '@/components/TickBox';

export default {
  name: 'AnswersTextResultList',
  props: ['options', 'answers'],
  data() {
    return {
      localOptions: this.options.map((option, i) => {
        return {
          value: option,
          correct: this.answers.includes(i + 1),
        };
      }),
    };
  },
  components: {
    TickBox,
  },
};
</script>
