<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="title-route">
        <img
          ref="titleImage"
          class="title-image"
          src="~@/assets/imgs/intro-header.png"
        />
        <div ref="startButton">
          <Button @click="start()">Start</Button>
        </div>
        <img
          ref="characterLeft"
          class="character character--left"
          src="~@/assets/imgs/character-one.png"
        />
        <img
          ref="characterRight"
          class="character character--right"
          src="~@/assets/imgs/character-three.png"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';

export default {
  name: 'TitleRoute',
  components: {
    Button,
    RouteWrapper,
  },
  methods: {
    start() {
      this.$router.replace({ name: 'intro' });
    },
    enter(el, done) {
      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.characterLeft,
          {
            y: '200%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0
        )
        .fromTo(
          this.$refs.characterRight,
          {
            y: '200%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        )
        .fromTo(
          this.$refs.titleImage,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        )
        .fromTo(
          this.$refs.startButton,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        );

      // Jangle the button
      this.buttonJangleTimeline = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 6,
          delay: 6,
        })
        .fromTo(
          this.$refs.startButton,
          0.1,
          {
            rotate: '-3',
          },
          {
            rotate: 3,
            repeat: 5,
            yoyo: true,
            ease: 'power1.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.characterLeft,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.characterRight,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.titleImage,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.startButton.$el,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.title-route {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 5;
}

/**
 * Big title text
 */
@media only screen and (min-width: 700px) {
  .title-route {
    padding: 0 80px;
  }
}

.title-image {
  width: 90%;
  height: auto;
  margin-bottom: 24px;
}

@media only screen and (min-width: 700px) {
  .title-image {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) {
  .title-image {
    width: 800px;
  }
}

/**
 * Characters
 */
.character {
  position: absolute;
  height: 200px;
  bottom: -40px;
  z-index: 5;
}

@media only screen and (min-width: 375px) {
  .character {
    height: 250px;
  }
}

@media only screen and (min-width: 600px) {
  .character {
    height: 375px;
    bottom: -75px;
  }
}

@media only screen and (min-width: 800px) {
  .character {
    height: 450px;
    bottom: -75px;
  }
}

.character--left {
  display: none;
  left: 20%;
  transform: rotate(5deg);
}

@media only screen and (min-width: 800px) {
  .character--left {
    display: block;
  }
}

.character--right {
  right: 0;
}

@media only screen and (min-width: 800px) {
  .character--right {
    display: none;
  }
}

@media only screen and (min-width: 1400px) {
  .character--right {
    display: block;
    right: 20%;
    height: 400px;
  }
}
</style>
