<template>
  <transition appear :css="false">
    <div class="loading">
      <div ref="logoContent" class="loading__content">
        <img
          @load="logoLoaded"
          class="loading__logo"
          src="~@/assets/imgs/irish-aid-logo.png"
        />
        <p class="loading__text">Loading ...</p>
      </div>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'LoadingRoute',
  created() {
    const images = [
      'answer-4-1.png',
      'arrow.png',
      'dark-blue-button-background.svg',
      'pink-button-background.svg',
      'tree-with-monkey.png',
      'answer-4-2.png',
      'blue-button-background.svg',
      'giraffe.png',
      'magazine-cover-one.jpg',
      'question-15-icon.png',
      'tree.png',
      'answer-4-3.jpg',
      'character-one.png',
      'green-square-background.svg',
      'magazine-cover-two.jpg',
      'question-16-icon.png',
      'wheel-center.svg',
      'answer-8-1.jpg',
      'character-three.png',
      'green-tick.png',
      'monkey.png',
      'stopwatch.png',
      'yellow-tick.png',
      'answer-8-2.png',
      'character-two.png',
      'intro-background.jpg',
      'orange-button-background.svg',
      'think-fast-badge.png',
      'answer-8-3.png',
      'cross.png',
      'intro-header.png',
      'orange-square-background.svg',
      'tick-box.png',
    ].map((fileName) => {
      return new Promise((resolve, reject) => {
        let img = new Image();
        let url = require(`@/assets/imgs/${fileName}`);
        img.onload = () => {
          resolve(true);
        };
        img.src = url;
      });
    });
    Promise.all(images).then(() => {
      setTimeout(() => {
        this.$router.replace({ name: 'title' });
      }, 2000);
    });
  },
  beforeRouteLeave(to, from, next) {
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.logoContent,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
        }
      );
  },
  methods: {
    logoLoaded() {
      gsap.timeline().fromTo(
        this.$refs.logoContent,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        }
      );
    },
  },
};
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(-3deg);
  }

  50% {
    transform: scale(1) rotate(-3deg);
  }

  100% {
    transform: scale(0.95) rotate(-3deg);
  }
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c9eef7;
}

.loading__content {
  transform: scale(1) rotate(-3deg);
  animation: pulse 2s infinite;
  z-index: 5;
  opacity: 0;
  max-width: 600px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading__logo {
  width: 100%;
}

.loading__text {
  width: 100%;
  color: #d52627;
  font-size: 4rem;
  line-height: 1;
  margin: 5px 0 0 0;
  font-family: 'StantonICG', 'Helvetica', 'sans-serif';
  text-align: center;
}
</style>
