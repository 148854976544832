<template>
  <div class="route-wrapper"><slot></slot></div>
</template>

<script>
export default {
  name: 'RouteWrapper',
};
</script>

<style>
.route-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
