<template>
  <ul class="answers-image-list">
    <li
      :class="
        'answers-image-list__item answers-image-list__item--' +
        (option.correct ? 'correct' : 'incorrect')
      "
      v-for="(option, i) in localOptions"
      :key="i"
    >
      <img
        class="answers-image-list__item__image"
        :src="require(`@/assets/imgs/${option.value}`)"
      />
      <span class="answers-image-list__item__checkbox">
        <TickBox :correct="option.correct"></TickBox>
      </span>
    </li>
  </ul>
</template>

<script>
import TickBox from '@/components/TickBox';
import { debounce } from '@/utils';

export default {
  name: 'AnswersImageResultList',
  props: ['options', 'answers'],
  data() {
    return {
      localOptions: this.options.map((option, i) => {
        return {
          value: option,
          correct: this.answers.includes(i + 1),
        };
      }),
    };
  },
  components: {
    TickBox,
  },
};
</script>
