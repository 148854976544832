<template>
  <ul class="answers-image-list answers-image-list--clickable">
    <li
      class="answers-image-list__item"
      v-for="(option, i) in localOptions"
      :key="i"
      @click="answerClicked(i)"
    >
      <img
        class="answers-image-list__item__image"
        :src="require(`@/assets/imgs/${option.value}`)"
      />
      <span class="answers-image-list__item__checkbox">
        <TickBox :checked="option.checked"></TickBox>
      </span>
    </li>
  </ul>
</template>

<script>
import TickBox from '@/components/TickBox';
import { debounce } from '@/utils';

export default {
  name: 'AnswersTextList',
  props: ['options'],
  data() {
    return {
      localOptions: this.options.map((option) => {
        return {
          value: option,
          checked: false,
        };
      }),
    };
  },
  components: {
    TickBox,
  },
  methods: {
    answerClicked: debounce(
      function (i) {
        if (this.localOptions[i].checked) {
          this.localOptions[i].checked = false;
        } else {
          this.localOptions.forEach((option) => (option.checked = false));
          this.localOptions[i].checked = true;
        }
      },
      function (i) {
        if (this.localOptions[i].checked) {
          this.$emit('answerClicked', i);
        }
      },
      1000
    ),
  },
};
</script>

<style>
.answers-image-list {
  margin: 15px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.answers-image-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.answers-image-list__item--incorrect {
  opacity: 0.5;
}

.answers-image-list__item__image {
  width: 100px;
  margin: 0 15px 15px 0;
  border-radius: 5px;
  border: 4px solid white;
}

@media only screen and (min-width: 1000px) {
  .answers-image-list {
    margin: 15px 0 0 0;
    flex-direction: row;
  }

  .answers-image-list__item__image {
    width: 150px;
    margin: 0 25px 25px 0;
  }

  .answers-image-list__item {
    flex-direction: column;
  }
}

.answers-image-list--clickable .answers-image-list__item {
  cursor: pointer;
}
</style>
