<template>
  <div>
    <ul class="answers-text-list answers-text-list--clickable">
      <li
        class="answers-text-list__item"
        v-for="(option, i) in localOptions"
        :key="i"
        @click="answerClicked(i)"
      >
        <span class="answers-text-list__item__checkbox">
          <TickBox :checked="option.checked"></TickBox>
        </span>
        <span class="answers-text-list__item__text">{{ option.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import TickBox from '@/components/TickBox';
import { debounce } from '@/utils';

export default {
  name: 'AnswersTextList',
  props: ['options'],
  data() {
    return {
      localOptions: this.options.map((option) => {
        return {
          value: option,
          checked: false,
        };
      }),
    };
  },
  components: {
    TickBox,
  },
  methods: {
    answerClicked: debounce(
      function (i) {
        if (this.localOptions[i].checked) {
          this.localOptions[i].checked = false;
        } else {
          this.localOptions.forEach((option) => (option.checked = false));
          this.localOptions[i].checked = true;
        }
      },
      function (i) {
        if (this.localOptions[i].checked) {
          this.$emit('answerClicked', i);
        }
      },
      1000
    ),
  },
};
</script>

<style>
.answers-text-list {
  margin: 0;
  padding: 0;
}

.answers-text-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
}

.answers-text-list__item--incorrect {
  opacity: 0.5;
}

.answers-text-list__item__checkbox {
  transition: all 200ms ease-in-out;
}
.answers-text-list__item__text {
  margin-left: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
  transition: all 200ms linear;
}

@media only screen and (min-width: 600px) {
  .answers-text-list__item__text {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 800px) {
  .answers-text-list__item__text {
    font-size: 2.3rem;
    font-weight: bold;
    margin-left: 25px;
  }
}

.answers-text-list--clickable .answers-text-list__item {
  cursor: pointer;
}

/* When hovering, animate the box */
.answers-text-list--clickable
  .answers-text-list__item:hover
  .answers-text-list__item__checkbox {
  transform: scale(1.025) translate3d(0, -3px, 0) rotate(-2deg);
  transition: all 200ms ease-in-out;
}
</style>
