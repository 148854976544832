<template>
  <div class="app">
    <RouterView></RouterView>
    <img
      @load="backgroundLoaded"
      ref="backgroundImage"
      class="app__background"
      src="~@/assets/imgs/intro-background.jpg"
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Global Goals Spinner Game',
    titleTemplate: '%s | Irish Aid 2021',
  },
  methods: {
    backgroundLoaded() {
      this.$refs.backgroundImage.style.opacity = 1;
    },
  },
};
</script>

<style>
.app {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.app__background {
  opacity: 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 1s linear;
  z-index: 0;
}
</style>
