<template>
  <button :class="classes">
    <img
      class="tickbox__background"
      src="~@/assets/imgs/tick-box.png"
      alt="Background"
    />
    <img
      class="tickbox__checked"
      v-if="checked === true"
      src="~@/assets/imgs/yellow-tick.png"
      alt="Checked"
    />
    <img
      class="tickbox__correct"
      v-if="correct === true"
      src="~@/assets/imgs/green-tick.png"
      alt="Correct"
    />
    <img
      class="tickbox__incorrect"
      v-if="correct === false"
      src="~@/assets/imgs/cross.png"
      alt="Incorrect"
    />
    <img
      class="tickbox__incorrect"
      v-if="showCheckedFalse === true && checked === false"
      src="~@/assets/imgs/cross.png"
      alt="Incorrect"
    />
  </button>
</template>

<script>
export default {
  name: 'TickBox',
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    checked: {},
    showCheckedFalse: {
      type: Boolean,
      default: false,
    },
    correct: {},
    incorrect: {},
    size: {
      type: String,
      default: 'large', // large | small
    },
  },
  // Play sound when value changes
  watch: {
    checked(a, b) {
      if (a !== b) {
        new Audio('/sounds/click.mp3').play();
      }
    },
  },
  computed: {
    classes() {
      let classes = `tickbox tickbox--${this.size}`;
      if (this.checked) {
        classes += ' tickbox--checked';
      } else {
        if (this.correct) {
          classes += ' tickbox--correct';
        }
        classes += ' tickbox--incorrect';
      }
      if (this.enabled) {
        classes += ' tickbox--enabled';
      }
      return classes;
    },
  },
};
</script>

<style scoped>
.tickbox {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 30px;
  width: 30px;
  z-index: 50;
  border: 0;
  background: none;
}

.tickbox--enabled {
  cursor: pointer;
}

.tickbox---enabled:active {
  top: 2px;
  left: 2px;
}

@media only screen and (min-width: 600px) {
  .tickbox {
    height: 40px;
    width: 40px;
  }
}

@media only screen and (min-width: 800px) {
  .tickbox {
    height: 60px;
    width: 60px;
  }
}

.tickbox--small {
  height: 20px;
  width: 20px;
}

@media only screen and (min-width: 600px) {
  .tickbox--small {
    height: 30px;
    width: 30px;
  }
}

@media only screen and (min-width: 800px) {
  .tickbox--small {
    height: 35px;
    width: 35px;
  }
}

.tickbox__background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.tickbox__checked,
.tickbox__correct,
.tickbox__incorrect {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.tickbox__incorrect {
  top: 3px;
  left: 10px;
}
</style>
