import Vue from 'vue';

const tickTock = new Audio('/sounds/clock.mp3');
tickTock.loop = true;

const timer = Vue.observable({
  timer: undefined,
  isEnabled: false,
  isRunning: false,
  isVisible: true,
  isExpanded: false,
  duration: 120,
  time: 120,
  show() {
    this.isVisible = true;
  },
  hide() {
    this.isVisible = false;
  },
  toggleShow() {
    this.isVisible = !this.isVisible;
  },
  expand() {
    this.isExpanded = true;
  },
  contract() {
    this.isExpanded = false;
  },
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  },
  start() {
    if (!this.isRunning) {
      tickTock.play();
      this.isRunning = true;
      return new Promise((resolve) => {
        this.timer = setInterval(() => {
          if (this.isRunning && this.time >= 1) {
            this.time--;
          } else {
            tickTock.pause();
            clearInterval(this.timer);
            // It finished naturally, versus being cancelled
            if (this.isRunning) {
              resolve();
              this.isRunning = false;
            }
          }
        }, 1000);
      });
    } else {
      console.warn('Timer is already running');
    }
  },
  reset() {
    this.isRunning = false;
    this.time = this.duration;
  },
});

export { timer };
