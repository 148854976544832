import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

import './assets/styles/index.css';

import TitleRoute from './routes/TitleRoute';
import IntroRoute from './routes/IntroRoute';
import PlayRoute from './routes/PlayRoute';
import GameRoute from './routes/GameRoute';
import CategoryRoute from './routes/CategoryRoute';
import QuestionSelectionRoute from './routes/QuestionSelectionRoute';
import QuestionRoute from './routes/QuestionRoute';
import LoadingRoute from './routes/LoadingRoute';

// Register Greensock plugins
import { gsap } from 'gsap';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { CustomEase } from 'gsap/CustomEase';
import Draggable from 'gsap/Draggable';
import QuestionCorrectRoute from '@/routes/QuestionCorrectRoute';
import QuestionDisplayRoute from '@/routes/QuestionDisplayRoute';
import QuestionIncorrectRoute from '@/routes/QuestionIncorrectRoute';
import QuestionAlreadyAnsweredRoute from '@/routes/QuestionAlreadyAnsweredRoute';
import FinishedRoute from '@/routes/FinishedRoute';

gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(Draggable);

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  { path: '/', component: LoadingRoute, name: 'loading' },
  {
    path: '/game',
    component: GameRoute,
    name: 'game',
    children: [
      { path: 'title', component: TitleRoute, name: 'title' },
      { path: 'intro', component: IntroRoute, name: 'intro' },
      {
        path: 'play',
        component: PlayRoute,
        name: 'play',
        children: [
          {
            path: 'category/:categoryId',
            component: CategoryRoute,
            name: 'category',
            props: true,
            children: [
              {
                path: 'choose-question',
                component: QuestionSelectionRoute,
                name: 'choose-question',
                props: true,
              },
              {
                path: 'question/:questionId',
                component: QuestionRoute,
                props: true,
                children: [
                  {
                    path: '',
                    component: QuestionDisplayRoute,
                    name: 'question-display',
                  },
                  {
                    path: 'correct',
                    component: QuestionCorrectRoute,
                    name: 'question-correct',
                  },
                  {
                    path: 'incorrect',
                    component: QuestionIncorrectRoute,
                    name: 'question-incorrect',
                  },
                  {
                    path: 'already-answered',
                    component: QuestionAlreadyAnsweredRoute,
                    name: 'question-already-answered',
                  },
                ],
              },
            ],
          },
        ],
      },
      { path: 'finished', component: FinishedRoute, name: 'finished' },
    ],
  },
];

const router = new VueRouter({
  routes,
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount('#app');
