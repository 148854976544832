<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="intro-route">
        <div class="container">
          <div class="row header">&nbsp;</div>
          <div ref="island" class="row island">
            <div class="island__column island__column--one">
              <p>
                <strong
                  >Spin the wheel to test your knowledge of the 17 Global Goals
                  for Sustainable Development</strong
                >
                and the work that Irish Aid does for the wellbeing of people and
                planet.
              </p>
              <p>Tick below to beat the clock or play at your own pace.</p>
              <div>
                <div class="timer-toggle" @click="toggleTimer()">
                  <TickBox
                    :checked="timer.isEnabled"
                    :showCheckedFalse="true"
                    :size="'small'"
                  ></TickBox>
                  <span>Play against the clock</span>
                  <img src="~@/assets/imgs/stopwatch.png" />
                </div>
              </div>
            </div>
            <div class="island__column island__column--two">
              <p>
                If you get stuck, you can find answers to most of the Global
                Goals Spinner questions in the 2022 Our World Irish Aid Award
                pupil's magazine (click on the image to access).
              </p>
              <ul class="magazines-list">
                <li class="magazines-list__item">
                  <a
                    href="https://www.ourworldirishaidawards.ie/pupils-magazine/"
                    target="_blank"
                  >
                    <img
                      src="~@/assets/imgs/magazine-cover-one.jpg"
                      alt="Partnership for the goals magazine"
                    />
                  </a>
                </li>
                <li class="magazines-list__item">
                  <a
                    href="https://www.ourworldirishaidawards.ie/pupils-magazine/"
                    target="_blank"
                  >
                    <img
                      src="~@/assets/imgs/magazine-cover-two.jpg"
                      alt="Partnership for the goals magazine"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <img
              class="island__background"
              src="~@/assets/imgs/green-square-background.svg"
            />
          </div>
          <div ref="footer" class="row footer">
            <Button @click="play()">Play</Button>
          </div>
        </div>
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import TickBox from '@/components/TickBox';
import { gsap } from 'gsap';
import { timer } from '@/timer';

export default {
  name: 'IntroRoute',
  components: {
    Button,
    RouteWrapper,
    TickBox,
  },
  data() {
    return {
      timer,
    };
  },
  methods: {
    play() {
      this.$router.replace({ name: 'play' });
    },
    toggleTimer() {
      timer.isEnabled = !timer.isEnabled;
    },
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.character,
          {
            y: '200%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        )
        .fromTo(
          this.$refs.island,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.5,
            autoAlpha: 1,
            ease: 'power1.out',
          },
          0.5
        )
        .fromTo(
          this.$refs.footer,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.5,
            autoAlpha: 1,
            ease: 'power1.out',
          },
          0.75
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.character,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.island,
          {
            y: 0,
          },
          {
            y: '-200%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.footer,
          {
            y: 0,
          },
          {
            y: '600%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.intro-route {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  z-index: 5;
}

@media only screen and (min-width: 875px) {
  .container {
    width: 80%;
    padding: 0;
    justify-content: space-around;
  }
}

@media only screen and (min-width: 1000px) {
  .container {
    width: 70%;
    justify-content: center;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    width: 900px;
  }
}

/**
 * Header
 **/
.header {
  height: 200px;
}

@media only screen and (min-width: 375px) {
  .header {
    height: 150px;
  }
}

@media only screen and (min-width: 420px) {
  .header {
    /* height: auto; */
  }
}

@media only screen and (min-width: 1000px) {
  .header {
    position: fixed;
    top: 15px;
    left: 50%;
    width: 60%;
    transform: translate3d(-50%, 0, 0);
  }
}

/**
 * Island and content
 */
.island {
  position: relative;
  margin: 0 20px;
  padding: 20px;
  color: white;
  font-size: 1.6rem;
  text-align: center;
  background-color: #5bb947;
  box-shadow: -6px -5px 0px 0px #ffffff;
  border-radius: 20px;
  overflow-y: auto;
}

@media only screen and (min-width: 600px) {
  .island {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 875px) {
  .island {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 50px;
    font-size: 2rem;
    overflow: inherit;
    margin: 0;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .island {
    font-size: 2.2rem;
  }
}

/**
 * Island background
 */
.island__background {
  display: none;
  position: absolute;
  height: 110%;
  width: 400%;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate3d(-50%, -50%, 0);
}

@media only screen and (min-width: 875px) {
  .island__background {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .island__background {
    height: 125%;
    width: 125%;
  }
}

.island__column {
  z-index: 2;
}

@media only screen and (min-width: 875px) {
  .island__column {
    width: 50%;
    margin: 0 10px;
  }
}

.island__column p {
  margin: 0;
}

@media only screen and (min-width: 100px) {
  .island__column p {
    margin-bottom: 15px;
  }
}

.island__column--two {
  margin-bottom: 20px;
}

.column p strong {
  font-size: 2rem;
  margin: 0;
}

.think-fast-badge {
  max-width: 250px;
  margin: 10px 0;
}

/**
 * Magazines
 */
.magazines-list {
  padding: 0;
  margin: 25px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.magazines-list__item {
  margin: 0 15px 0 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
  transition: transform ease-in-out 100ms;
}

.magazines-list__item:last-child {
  margin: 0;
}

.magazines-list__item img {
  display: block;
  max-width: 100%;
  width: 100px;
}

@media only screen and (min-width: 1000px) {
  .magazines-list__item img {
    width: 100px;
  }
}

.magazines-list__item:hover {
  transform: scale(1.025);
}

/**
 * Footer and button
 **/
.footer {
  margin-top: 15px;
}

@media only screen and (min-width: 875px) {
  .footer {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .footer {
    margin-top: 100px;
  }
}

/**
 * Character
 */
.character {
  position: absolute;
  display: none;
  height: 350px;
  bottom: -75px;
  z-index: 5;
  transform: rotate(-5deg);
}

@media only screen and (min-width: 800px) {
  .character {
    display: block;
    left: 5%;
  }
}

@media only screen and (min-width: 1000px) {
  .character {
    left: 10%;
    height: 400px;
    bottom: -75px;
  }
}

/**
 * Timer toggle
 */
.timer-toggle {
  position: relative;
  padding: 10px 50px 10px 30px;
  font-size: 1.8rem;
  margin: 10px 0 15px 0;
  cursor: pointer;
  display: inline-flex;
  width: auto;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 3px solid #fdc200;
  border-radius: 50px;
  line-height: 1;
  font-family: 'DIN Next LT Pro', 'Helvetica', 'sans-serif';
  letter-spacing: -1px;
  font-weight: 600;
  color: #ee005e;
}

.timer-toggle span {
  margin-left: 10px;
}

.timer-toggle img {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-55%);
  height: 55px;
}

@media only screen and (min-width: 875px) {
  .timer-toggle {
    font-size: 2rem;
    padding: 10px 70px 10px 40px;
  }

  .timer-toggle img {
    height: 70px;
  }
}

@media only screen and (min-width: 1000px) {
  .timer-toggle {
  }
}
</style>
