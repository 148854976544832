<template>
  <img src="~@/assets/imgs/arrow.png" alt="-->" />
</template>

<script>
export default {
  name: 'Button',
};
</script>

<style scoped>
button {
  display: block;
  background-image: url('~@/assets/imgs/pink-button-background.svg');
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 40px;
  color: white;
  font-size: 6rem;
  line-height: 1;
  font-family: 'StantonICG', 'Helvetica', 'sans-serif';
  transition: transform ease-out 100ms;
}

button:hover {
  transform: scale(1.1);
}

button span {
  display: block;
  transition: transform ease-out 150ms;
}

button:hover span {
  transform: scale(1.1);
}
</style>
