<template>
  <transition :css="false" appear @enter="enter">
    <RouteWrapper>
      <div class="question-correct-route">
        <SpeechBubble ref="bubble" :size="'large'" :color="'#01699D'">
          <template v-slot:header>
            <div class="question-already-answered__header">
              <h2>Already Answered</h2>
              <p class="header-text">
                You have already answered this question. Please spin again.
              </p>
            </div>
          </template>
        </SpeechBubble>
        <RouterLink :to="{ name: 'play' }" replace>
          <Button ref="spinAgainButton">Spin Again</Button>
        </RouterLink>
      </div>
    </RouteWrapper>
  </transition>
</template>

<script>
import SpeechBubble from '@/components/SpeechBubble';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import { gsap } from 'gsap';

export default {
  name: 'QuestionAlreadyAnsweredRoute',
  components: {
    SpeechBubble,
    Button,
    RouteWrapper,
  },
  props: ['category', 'question'],
  beforeRouteLeave(to, from, next) {
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.bubble.$el,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y: '40px',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power3.out',
        }
      )
      .fromTo(
        this.$refs.spinAgainButton.$el,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y: '50px',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
        }
      );
  },
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 0.75,
        })
        .fromTo(
          this.$refs.bubble.$el,
          {
            y: '-40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power3.out',
          }
        )
        .fromTo(
          this.$refs.spinAgainButton.$el,
          {
            y: '50px',
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power1.out',
          }
        );
    },
  },
};
</script>

<style scoped>
.question-correct-route {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.question-already-answered__header {
  margin: 50px 0;
}
</style>
